// Deves környezet
// clientApiUrl:
//  - Az az url amit a böngésző használ az API hívásokhoz
// serverApiUrl:
//  - Az az url amit SSR-nél a Node használ az API hívásokhoz
// translation:
//  - locales: az oldalon használt nyelvek listája
//  - prefix: a route fordítókulcsok prefixe
//  - localesFolder: a mappa elérési útja, ahol a fordításhoz használt {lang}.JSON fájlok találhatók.

import { IEnvironment } from './environment.definitions';

export const environment: IEnvironment = {
  production: false,
  type: 'dev',
  apiUrl: 'https://csodasmagyarorszag-api.dev.trendency.hu/publicapi',
  domainReplacements: [
    {
      includes: '/every-season/',
      replaces: [ 'csodasmagyarorszag-api.dev.trendency.hu', 'wowhungary-api.dev.trendency.hu' ]
    },
  ],
  domain: 'https://csodasmagyarorszag.dev.trendency.hu/',
  weatherApiUrl: 'https://185.161.73.82',
  translation: {
    locales: ['hu', 'en', 'es', 'cs', 'de', 'fr', 'it', 'pl', 'ro', 'sk', 'he', 'hr', 'ko', 'ru', 'sl', 'cn', 'sr', 'tr'],
    prefix: 'ROUTES.',
    localesFolder: {
      client: '/assets/locales',
      server: '/../browser/assets/locales',
    },
  },
  googleApiKey: 'AIzaSyAbmRyjYwZTFXfBD8ORDispcQgrh3MzsVI',
  googleAnalyticsCode: 'GTM-MQ8ZHJK',
};
